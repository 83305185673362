import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styles from './sass/SignInView.module.scss';

const SignInView = ({ providers, csrfToken }) => {
  const { t } = useTranslation('auth');
  const { query } = useRouter();
  const { hydra_passport: hydraProvider } = providers;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>
          {t('welcomeTo')}
          <strong> HYDRA.</strong>
          projects
        </h1>
      </header>
      {hydraProvider && (
        <div className={styles.provider}>
          <form action={hydraProvider.signinUrl} method="POST">
            <input type="hidden" name="csrfToken" value={csrfToken} />
            <input type="hidden" name="callbackUrl" value={query.callbackUrl} />
            <button type="submit" className={styles.btn}>
              {t('signInWith')}
              <strong> HYDRA.</strong>
              passport
            </button>
          </form>
        </div>
      )}
      <footer className={styles.footer}>
        <span>{t('byOnix')}</span>
      </footer>
    </div>
  );
};

SignInView.propTypes = {
  providers: PropTypes.shape({
    hydra_passport: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      signinUrl: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default SignInView;
